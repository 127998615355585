@import '../../mixins';

.success-message {
  @include small-mobile-only {
    flex-direction: column;
  }

  @include icon("success", 8rem);

  align-items: center;
  color: $textColorLighter;
  display: flex;
  font-size: map-get($fontSizes, small);
  margin-bottom: 3.2rem;
  margin-top: 2.4rem;

  &::before {
    @include small-mobile-only {
      margin-bottom: 2.4rem;
      margin-right: 0;
    }

    display: block;
    margin-right: 3.2rem;
  }
}
