@import "../../mixins";

.step-container {
  display: flex;
  justify-content: center;
}

.box {
  @include mobile-only {
    @include side-padding;

    box-shadow: none;
    width: 100%;
  }

  border-radius: $basicBorderRadius;
  box-shadow: 0 0.05rem 0.4rem 0.1rem $boxShadowColor;
  box-sizing: border-box;
  color: $textColorLighter;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  padding: 2.4rem;
  width: 92.6rem; // 926px (if 1rem = 10px)

  .header {
    color: $textColor;
    font-size: map-get($fontSizes, large);
    font-weight: bold;
  }
}

.amount {
  color: $textColor;
  font-size: map-get($fontSizes, xx-large);
  font-weight: 300;
  margin-bottom: 3.2rem;

  &__small-text {
    font-size: map-get($fontSizes, medium);
    font-weight: normal;
  }
}
