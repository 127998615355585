@import "../../mixins";

.progress-bar {
  @include small-mobile-only {
    @include side-padding;

    width: 100%;
  }

  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: map-get($fontSizes, x-small);
  font-weight: bold;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.8rem;
  position: relative;
  width: 30rem;

  &::before {
    @include small-mobile-only {
      left: $basicSidePadding;
      width: calc(100% - 2 * #{$basicSidePadding});
    }

    border-top: 1px solid $lightLineColor;
    content: " ";
    display: block;
    left: 0;
    position: absolute;
    top: 1rem;
    width: 100%;
  }
}

.progress-step {
  align-items: center;
  background: $lightLineColor;
  border-radius: 50%;
  color: $textColorLighter;
  display: flex;
  height: 2rem;
  justify-content: center;
  position: relative;
  width: 2rem;

  .label {
    bottom: -1.8rem;
    color: $textColorLighter;
    font-size: map-get($fontSizes, small);
    font-weight: 300;
    position: absolute;
  }

  &--active,
  &--checked {
    background: $primaryColor;
    color: $white;

    .label {
      color: $primaryColor;
      font-weight: bold;
    }
  }

  &--checked {
    .number {
      @include icon("tick", 1.2rem);

      &::before {
        display: block;
      }
    }
  }
}
