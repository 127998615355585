@import '../mixins';

.alert {
  @include small-mobile-only {
    margin-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
    width: 100%;
  }

  background: $errorBackgroundColor;
  border-radius: $basicBorderRadius;
  box-sizing: border-box;
  color: $errorColor;
  display: flex;
  font-size: map-get($fontSizes, medium);
  line-height: 2rem;
  min-height: 2rem;
  min-width: 16rem;
  padding-bottom: 1rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-top: 1rem;

  &.fullWidth {
    margin-top: 1.6rem;
  }

  &.right-aligned {
    margin-left: auto;
    width: calc(50% - 7.2rem);

    @include mobile-only {
      flex-direction: column;
    }

    @include small-mobile-only {
      width: 100%;
    }
  }

  &.left-aligned {
    display: inline-flex;
    min-width: 30rem;

    @include mobile-only {
      flex-direction: column;
    }
  }
}

.label {
  .alert:not(.fullWidth) & {
    @include mobile-only {
      margin-bottom: 1.6rem;
    }
  }

  font-weight: bold;
  margin-right: 3.6rem;
}

.fullWidth {
  @include small-mobile-only {
    display: flex;
  }
}