@import '../mixins';

.amount {
  color: $textColor;
  font-size: map-get($fontSizes, xx-large);
  font-weight: 300;
  margin-bottom: 3.2rem;

  .small-text {
    font-size: map-get($fontSizes, medium);
    font-weight: normal;
  }
}
