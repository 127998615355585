@import 'src/mixins';

.tableHeader {
  font-size: map-get($fontSizes, small);
  color: $textColorLighter;
  margin-top: 3.2rem;
}

.table {
  font-size: map-get($fontSizes, small);
  margin-bottom: 4.8rem;
  margin-top: 2.4rem;

  td {
    min-width: 14rem;
  }

  th {
    color: $textColor;
    padding-bottom: 0.6rem;
    text-align: left;
  }
}

.buttonWrapper {
  margin-top: 4.8rem;
}