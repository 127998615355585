@import '../mixins';

.container {
  @include small-mobile-only {
    @include side-padding;

    margin-top: 1.6rem;
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  max-width: 100%;
  width: 36rem;
}

.rcbc-logo {
  height: 8.2rem;
  margin-bottom: 3.6rem;
  width: 18rem;
}

.error {
  color: $errorColor;
  font-size: map-get($fontSizes, medium);
}
