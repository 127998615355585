@import '../mixins';

.logo {
  height: $buttonHeight;
  width: 18.8rem;

  & + .logo {
    margin-left: 1.6rem;
  }

  &--diskartech {
    width: 11.3rem;
  }

  &--nextbank-small {
    height: 2.4rem;
    width: 13.3rem;
  }

  &--rcbc {
    width: 4.1rem;
  }
}
