@import 'src/mixins';

.label {
  @include default-transition;

  background-color: $white;
  color: $textColorLighter;
  font-size: map-get($fontSizes, small);
  font-weight: normal;
  left: 0.8rem;
  line-height: 1;
  margin-top: -0.6rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: map-get($zIndex, prioritized);
}

.input-wrapper {
  @include small-mobile-only {
    flex-wrap: wrap;
  }

  align-items: center;
  display: flex;
  margin-bottom: 2.4rem;
  position: relative;
  width: 100%;

  .tooltip {
    @include small-mobile-only {
      flex: auto;
      margin-bottom: 1.6rem;
      margin-top: 1.6rem;
      padding-left: 0;
    }

    color: $textColorLighter;
    font-size: map-get($fontSizes, small);
    padding-left: 7.2rem;
  }
}

.input {
  @include small-mobile-only {
    flex: auto;
  }

  @include default-transition;
  @include side-padding;

  appearance: none;
  background: transparent;
  border: 1px solid $lightBorderColor;
  border-radius: $basicBorderRadius;
  box-sizing: border-box;
  color: $textColor;
  display: block;
  flex-shrink: 0;
  font-size: map-get($fontSizes, medium);
  height: $basicElementHeight;
  margin-bottom: 0;
  transition: border-color 0.2s ease;
  width: 50%;

  &::placeholder {
    color: $textColorLighter;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // sass-lint:disable no-vendor-prefixes
  &:-webkit-autofill {
    box-shadow: 0 0 0 8rem $white inset;
  }

  .full-width & {
    width: 100%;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  // sass-lint:enable no-vendor-prefixes

  &__error {
    border-color: $errorColor !important;

    & ~ .label {
      color: $errorColor;
    }
  }

  &:disabled {
    color: $textColorLighter;
  }

  &:enabled {
    &:focus,
    &:hover,
    &:active {
      border-color: $primaryColor;
      outline: none;

      & ~ .label {
        color: $primaryColor;
      }
    }
  }
}

.textarea {
  @include mobile-only {
    min-height: 9rem;
  }

  line-height: 1.8rem;
  max-width: 50%;
  min-height: 11rem;
  padding-top: 1.6rem;
}