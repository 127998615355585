@import '../../mixins';

.text {
  color: $textColor;
  font-size: map-get($fontSizes, large);
}

.tooltip {
  color: $textColorLighter;
  display: flex;
  margin-bottom: 3.6rem;

  &__text {
    padding-left: 1.2rem;
  }
}

.help {
  @include icon("info", 1.4rem);

  &::before {
    display: block;
  }
}

.buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.6rem;

  & > *:not(:last-of-type) {
    margin-right: 4.2rem;
  }

  @include small-mobile-only {
    flex-direction: column;
    width: 100%;

    & > *:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }
}