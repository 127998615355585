@import "../../mixins";

.top-bar {
  @include mobile-only {
    @include side-padding;

    width: 100%;
  }

  align-items: center;
  box-sizing: border-box;
  display: flex;
  width: $mainContainerWidth;
  z-index: map-get($zIndex, prioritized);
}

.top-bar-container {
  box-shadow: 0 0.075rem 0.5rem 0.25rem $lightLineColor;
  display: flex;
  height: $topBarHeight;
  justify-content: center;
}

.buttons {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.loading {
  color: $textColorLighter;
  font-size: map-get($fontSizes, small);
  margin-bottom: 0;
}