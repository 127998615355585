@import "../../mixins";

.footer {
  @include mobile-only {
    @include side-padding;

    width: 100%;
  }

  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  width: $mainContainerWidth;
  justify-content: flex-end;
  z-index: map-get($zIndex, prioritized);

  &__label {
    color: $textColorLighter;
    margin-right: 1.6rem;
  }
}

.footer-container {
  align-items: center;
  display: flex;
  font-size: map-get($fontSizes, small);
  height: $footerHeight;
  width: 100%;
  margin-top: auto;
  justify-content: center;
}
