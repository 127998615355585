@import '../mixins';

.wrapper {
  @include small-mobile-only {
    flex-direction: column;
    width: 100%;
  }

  display: flex;

  &.full-width {
    flex-direction: column;
  }
}

.button {
  @include small-mobile-only {
    width: 100%;
  }

  @include default-transition;

  background: $primaryColor;
  border: 0;
  border-radius: $basicBorderRadius;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  font-size: map-get($fontSizes, small);
  font-weight: bold;
  height: $buttonHeight;
  min-width: 16rem;
  outline: none;
  padding-left: $basicSidePadding;
  padding-right: $basicSidePadding;
  position: relative;

  &--outline {
    background: $white;
    border: 1px solid $primaryColor;
    color: $primaryColor;
  }

  &:disabled {
    background-color: $primaryDisabledColor;
    cursor: default;
  }
}

.button-wrapper {
  @include small-mobile-only {
    flex-direction: column;
    width: 100%;
  }

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 50%;

  .full-width & {
    width: 100%;
  }

  .button {
    @include small-mobile-only {
      margin-right: 0;
    }

    margin-right: 1.2rem;

    .full-width & {
      width: 100%;
      margin-right: 0;
    }

    & + .button {
      @include small-mobile-only {
        margin-left: 0;
        margin-top: 1.6rem;
      }

      margin-left: 1.2rem;
      margin-right: 0;
    }

    &:enabled {
      &:hover {
        background-color: $primaryHoverColor;
        color: $white;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
      }

      &:active {
        background-color: $primaryActiveColor;
      }
    }
  }
}