$primaryColor: #347cff;
$primaryHoverColor: #{darken($primaryColor, 15%)};
$primaryAccentColor: #{lighten($primaryColor, 20%)};
$primaryActiveColor: #2c6bfc;
$primaryDisabledColor: #a4c4ff;

$errorColor: #fd545f;
$errorBackgroundColor: #fbe9ee;
$warningColor: #ff9a00;
$warningBackgroundColor: #fef2dd;
$successColor: #49c754;
$infoColor: #347cff;

$textColor: #303440;
$textColorLighter: #717781;
$lightBorderColor: #d4d8df;
$lightLineColor: #eee;
$boxShadowColor: #dadada;

$white: #fff;

$mainContainerWidth: 92.6rem;
$buttonHeight: 3.4rem;
$basicElementHeight: 3.8rem;
$basicBorderRadius: 0.4rem;
$basicSidePadding: 1.6rem;
$topBarHeight: 5.6rem;
$footerHeight: 5.6rem;

$baseFontSize: 10px;

$fontSizes: (
  xx-large: 4rem, // 40px (if 1rem = 10px)
  x-large: 1.9rem, // 19px (if 1rem = 10px)
  large: 1.6rem, // 16px (if 1rem = 10px)
  medium: 1.4rem, // 14px (if 1rem = 10px)
  small: 1.2rem, // 12px (if 1rem = 10px)
  x-small: 1rem // 10px (if 1rem = 10px)
);

$breakpoints: (
  mobile: 32rem,
  tablet: 76.8rem,
  desktop: 102.4rem,
  wide: 136.6rem
);

$zIndex: (
  standard: 1,
  prioritized: 2
);
