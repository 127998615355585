@import "./mixins";
@import './fonts';

:global {
  html {
    font-size: $baseFontSize;
  }

  body {
    @include main-font;

    color: $textColor;
    margin: 0;
    overflow-y: scroll;
    padding: 0;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  h1 {
    font-size: map-get($fontSizes, x-large);
    font-weight: 300;
    line-height: 3.2rem;
    margin: 0;
  }

  p {
    font-size: map-get($fontSizes, medium);
    margin-bottom: 1.6rem;
    margin-top: 0;
  }

  a {
    outline: none;
  }
}
