@import "./variables";

@mixin main-font {
  font-family: "PublicSans", Arial, sans-serif;
}

@mixin default-transition {
  transition: 0.2s ease;
}

@mixin side-padding {
  padding-left: $basicSidePadding;
  padding-right: $basicSidePadding;
}

@mixin small-mobile-only {
  @media screen and (max-width: (map-get($breakpoints, mobile) - 1)) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: (map-get($breakpoints, tablet) - 1)) {
    @content;
  }
}

@mixin icon($iconName, $iconWidth: 2rem, $iconHeight: $iconWidth, $backgroundSize: auto) {
  &::before {
    background: url("./icons/icon-#{$iconName}.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: $backgroundSize;
    content: " ";
    display: inline-block;
    height: $iconHeight;
    width: $iconWidth;
  }
}
