// sass-lint:disable no-duplicate-properties indentation
@font-face {
  font-family: "PublicSans";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/PublicSans-SemiBold.eot");
  src:
    url("./fonts/PublicSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/PublicSans-SemiBold.woff2") format("woff2"),
    url("./fonts/PublicSans-SemiBold.woff") format("woff"),
    url("./fonts/PublicSans-SemiBold.ttf") format("truetype"),
    url("./fonts/PublicSans-SemiBold.svg#PublicSans-SemiBold") format("svg");
}

@font-face {
  font-family: "PublicSans";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/PublicSans-Regular.eot");
  src:
    url("./fonts/PublicSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/PublicSans-Regular.woff2") format("woff2"),
    url("./fonts/PublicSans-Regular.woff") format("woff"),
    url("./fonts/PublicSans-Regular.ttf") format("truetype"),
    url("./fonts/PublicSans-Regular.svg#PublicSans-Regular") format("svg");
}

@font-face {
  font-family: "PublicSans";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/PublicSans-Light.eot");
  src:
    url("./fonts/PublicSans-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/PublicSans-Light.woff2") format("woff2"),
    url("./fonts/PublicSans-Light.woff") format("woff"),
    url("./fonts/PublicSans-Light.ttf") format("truetype"),
    url("./fonts/PublicSans-Light.svg#PublicSans-Light") format("svg");
}
